import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "https://api.ppzx168.com.cn/v1/user"

// get请求
export function getListAPI(params){
    return http.get(`${resquest}/search_by_date?tags=story`,params)
}
// 轮播图
export function getBannerList(params){
    return http.post(`${resquest}/banners/list`,params)
}

// 视频列表
export function getVideoList(params){
    return http.post(`${resquest}/videos/list`,params)
}

// 获取短视频总页数
export function getVideoPage(params){
    return http.post(`${resquest}/videos/video_page`,params)
}

// 短视频详情
export function shopVideosRead(params){
    return http.post(`${resquest}/videos/read`,params)
}

// 视频列表评论
export function getVideoCommentsList(params){
    return http.post(`${resquest}/video_comments/list`,params)
}

// 视频列表评论详情
export function getVideoCommentsRead(params){
    return http.post(`${resquest}/video_comments/read`,params)
}

// 商品列表
export function getGoodsList(params){
    return http.post(`${resquest}/goods/list`,params)
}

// 商家列表
export function shopsList(params){
    return http.post(`${resquest}/shops/list`,params)
}
// 商家详情
export function shopsRead(params){
    return http.post(`${resquest}/shops/read`,params)
}

// 直播商品
export function liveGoods(params){
    return http.post(`${resquest}/goods/goods_sort`,params)
}

// 配置
export function rulesRead(params){
    return http.post(`${resquest}/rules/read`,params)
}

// put 请求
export function putSomeAPI(params){
    return http.put(`${resquest}/putSome.json`,params)
}
// delete 请求
export function deleteListAPI(params){
    return http.delete(`${resquest}/deleteList.json`,params)
}

